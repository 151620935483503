import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/layouts/MdxLayout.js";
import SEO from "../components/Seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Terms of Use" mdxType="SEO" />
    <article className="mx-auto prose prose-xl">
      <h1 {...{
        "id": "terms-of-use"
      }}>{`Terms of Use`}</h1>
      <p><strong parentName="p">{`1. Definitions`}</strong></p>
      <p>{`“Archibald James”, “we”, “us”, or “our” means Archibald James, LLC, a Washington state limited liability company.
“Website” means the Archibald James website (archibaldjames.com).
“Terms” means these Terms of Use.
“Including” or “includes”, as used in these Terms, mean “including but not limited to”.
“Use” or “using” the Website means (includes) accessing, viewing, downloading or uploading data, or in any way interacting with the Website.
“You” or “your” means anyone using the Website.`}</p>
      <p><strong parentName="p">{`2. General`}</strong></p>
      <p>{`Use of the Website is only available to those who both (a) are United States residents of twenty-one (21) years of age or older and (b) comply with and agree to these Terms, as well as any other policies, terms, and conditions referenced on the Website. By using the Website, you affirm you are at least 21 years of age, you are resident in the United States, and you agree to be legally bound by these Terms.`}</p>
      <p><strong parentName="p">{`3. Changes to these Terms`}</strong></p>
      <p>{`We reserve the right to change these Terms at any time, without sending you notice, at our sole discretion. Your continued use of the Website constitutes your acceptance of the latest version of these Terms. The updated Terms will be available on the Website.`}</p>
      <p><strong parentName="p">{`4. Eligibility and age restriction`}</strong></p>
      <p>{`To use the Website, you must be an individual of at least twenty-one (21) years of age and reside in the United States. The products and services offered by Archibald James are not appropriate for anyone under 21 years of age. Our business, including our advertising, marketing activities, and products, are not intended for reception by anyone under 21 years of age. We do not knowingly solicit, request, or accept information from anyone under age 21. By using the Website, you are representing that you are at least 21 years of age.`}</p>
      <p><strong parentName="p">{`5. Marks and content`}</strong></p>
      <p>{`Our trade names, including “Archibald James” and the “vise” logo and other graphics and logos (collectively “Archibald James Marks”) are our trademarks or registered trademarks. The contents of the Website, including the Archibald James Marks are protected by copyright, trademark, and other proprietary rights.`}</p>
      <p><strong parentName="p">{`6. Online orders`}</strong></p>
      <p>{`To purchase our products online, you must be an individual of at least twenty-one (21) years of age. Additionally, you must reside in and place your order from a jurisdiction in which we are permitted to sell our products. Your age and geography will be requested before completing a transaction. Your age will also be verified, and a signature will be required, before delivery of any products purchased online.`}</p>
      <p><strong parentName="p">{`7. Shipping policies`}</strong></p>
      <ul>
        <li parentName="ul">{`Shipments are typically delivered by a third-party fulfilment partner (e.g. UPS).`}</li>
        <li parentName="ul">{`We can only ship to certain US jurisdictions. Regulations for shipping wine/cider/alcohol products vary by state. We comply with all regulations based on each state’s individual shipping laws, taxes, and limitations. We cannot ship to every US state, and we cannot ship outside the US.`}</li>
        <li parentName="ul">{`We cannot ship to PO Boxes or APO/FPO addresses.`}</li>
        <li parentName="ul">{`The fulfulment partner will require the signature of someone at least 21 years of age before delivery.`}</li>
        <li parentName="ul">{`The fulfilment partner may attempt delivery up to three times, after which the shipment will be returned to the sender.`}</li>
        <li parentName="ul">{`If the fulfilment partner cannot, for any reason, deliver the shipment, return shipping and re-shipping costs may be charged to the customer.`}</li>
        <li parentName="ul">{`Damaged shipments may be insured. Please notify us if you receive a damaged shipment.`}</li>
        <li parentName="ul">{`Once the order is processed, the products are considered purchased, even if returned.`}</li>
        <li parentName="ul">{`The customer is responsible for shipping, return shipping, and re-shipping costs, as well as any associated packaging costs.`}</li>
        <li parentName="ul">{`We want you to be satisfied with your purchase. If you have a problem, contact us directly.`}</li>
      </ul>
      <p><strong parentName="p">{`8. Club policies`}</strong></p>
      <ul>
        <li parentName="ul">{`When registering for wine club membership, you are affirming that you are 21 years of age or older and that you agree to the Terms.`}</li>
        <li parentName="ul">{`Clubs are automatic delivery programs where you agree to have the payment for the order processed automatically and recurrently.`}</li>
        <li parentName="ul">{`Membership will be ongoing until we receive a request to terminate membership.`}</li>
        <li parentName="ul">{`We make products in small batches. We often sell out, so we may substitute a different product in your shipment. The contents of club shipments are selected at our discretion and are subject to change without notice.`}</li>
        <li parentName="ul">{`Current and accurate contact, billing, and shipping information must be provided and kept up-to-date by the customer.`}</li>
        <li parentName="ul">{`If you agree to have products shipped to you, it is not possible to pick up your order in person. We cannot refund shipping costs.`}</li>
        <li parentName="ul">{`If your order is delayed for order processing, shipping, or delivery reasons, your order will not be available indefinitely. We may run out of products, so we’ll work with you to find an alternate product selection.`}</li>
        <li parentName="ul">{`We request a one year commitment—early termination fee may be imposed.`}</li>
        <li parentName="ul">{`You must notify us via email at least 30 days prior to shipping with any changes or cancellations. This time frame also applies to pick-ups.`}</li>
      </ul>
      <p><strong parentName="p">{`9. Privacy, personal data`}</strong></p>
      <p>{`By using the Website, you consent to our privacy policy.`}</p>
      <p><strong parentName="p">{`10. Warranties and disclaimers`}</strong></p>
      <p>{`WE MAKE NO WARRANTY, EXPRESS OR IMPLIED, CONCERNING THE WEBSITE. THE WEBSITE AND PRODUCTS PROVIDED BY US AND OUR THIRD-PARTY PROVIDERS ARE PROVIDED ON AN "AS IS" BASIS. WE EXPRESSLY DISCLAIM ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, INCLUDING ANY WARRANTY WITH RESPECT TO THE CORRECTNESS, QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, PERFORMANCE, TIMELINESS, OR CONTINUED AVAILABILITY OF THE WEBSITE OR ANY WEBSITE INFORMATION. ALL DISCLAIMERS OF ANY KIND (INCLUDING IN THIS SECTION AND ELSEWHERE IN THESE TERMS) ARE MADE FOR THE BENEFIT OF BOTH ARCHIBALD JAMES, LLC, AND ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, AND REPRESENTATIVES, AND THEIR RESPECTIVE SUCCESSORS AND ASSIGNS.
THE WEBSITE INFORMATION, SOFTWARE, PRODUCTS, AND DESCRIPTIONS OF SERVICES PUBLISHED OR MADE AVAILABLE THROUGH THE WEBSITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS, AND WE SPECIFICALLY DISCLAIM ANY LIABILITY FOR SUCH INACCURACIES OR ERRORS. WE DO NOT WARRANT OR REPRESENT THAT THE WEBSITE INFORMATION IS COMPLETE, CORRECT, SECURE, OR UP-TO-DATE. ANY DATED INFORMATION IS PUBLISHED AS OF ITS DATE ONLY AND WE DO NOT HAVE ANY OBLIGATION TO UPDATE THAT INFORMATION. NEITHER WE NOR ANY OF OUR THIRD-PARTY PROVIDERS HAVE ANY RESPONSIBILITY TO MAINTAIN THE DATA, WEBSITE INFORMATION, OR PRODUCTS MADE AVAILABLE THROUGH THE WEBSITE OR TO SUPPLY ANY CORRECTIONS, UPDATES, OR RELEASES IN CONNECTION WITH THAT DATA, WEBSITE INFORMATION OR PRODUCTS. THE WEBSITE INFORMATION AND AVAILABILITY OF THE WEBSITE IS SUBJECT TO CHANGE WITHOUT NOTICE. WE DO NOT REPRESENT OR GUARANTEE THAT THE WEBSITE WILL BE AVAILABLE OR FREE FROM LOSS, ATTACK, HACKING, OR OTHER SECURITY INTRUSION, AND WE EXPRESSLY DISCLAIM LIABILITY FOR ANY SECURITY-RELATED LOSSES OR DAMAGES.`}</p>
      <p><strong parentName="p">{`11. No liability`}</strong></p>
      <p>{`YOU AGREE THAT YOUR ACCESS TO AND USE OF THE WEBSITE IS AT YOUR OWN RISK. IN NO EVENT SHALL WE BE HELD LIABLE FOR (A) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, LOSSES, OR EXPENSES ARISING IN CONNECTION WITH YOUR USE OF THE WEBSITE, WEBSITE INFORMATION, ANY PRODUCT (INCLUDING ANY APPLICATION FOR SUCH PRODUCT), OR ANY THIRD-PARTY WEBSITE, OR (B) ANY WEBSITE FAILURE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN OPERATION OR TRANSMISSION; COMPUTER VIRUS; OR LINE OR SYSTEM FAILURE, EVEN IF WE OR OUR REPRESENTATIVES ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES, OR EXPENSES.`}</p>
      <p><strong parentName="p">{`12. Severability, no waiver`}</strong></p>
      <p>{`If any provision of these Terms is deemed unlawful, void, or unenforceable for any reason, then such provision shall be deemed to be removed from these Terms and shall not affect the validity and enforceability of any remaining provisions. Our failure to enforce the strict performance of any provision of these Terms or additional terms will not waive our right to later enforce those provisions.`}</p>
      <p><strong parentName="p">{`13. Governing law`}</strong></p>
      <p>{`These Terms shall be governed and construed in accordance with federal law and any applicable laws of the State of Washington, regardless of rules concerning conflict of law or choice of law.`}</p>
      <h1 {...{
        "id": "privacy-policy"
      }}><strong parentName="h1">{`Privacy Policy`}</strong></h1>
      <p>{`Posted 26 May 2019`}</p>
      <p><strong parentName="p">{`1. Definitions`}</strong></p>
      <p>{`“Archibald James”, “we”, “us”, or “our” means Archibald James, LLC, a Washington state limited liability company.
“Website” means the Archibald James website (archibaldjames.com).
“Privacy Policy” means the terms and policies described in this document.
“Including” or “includes”, as used in this Privacy Policy, mean “including but not limited to”.
“Use” or “using” the Website means (includes) accessing, viewing, downloading or uploading data, or in any way interacting with the Website.
“You” or “your” means anyone using the Website.`}</p>
      <p><strong parentName="p">{`2. General`}</strong></p>
      <p>{`Your privacy is important, and we want to be transparent about how we collect and use data on our Website.
This document (the “Privacy Policy”) describes our data collection, retention, disclosure, and use policies.
According to the Website Terms of Use, the Website is only made available to individuals resident in the United States of twenty-one (21) years of age or older.
By using the Website, you are accepting the terms of this Privacy Policy, and you are consenting to our collection, use, and disclosure of your information as described in this Privacy Policy.
This Privacy Policy may be updated occasionally, and the latest version will be available on the Website. Any changes will be effective from the time they are posted to the Website. Your continued use of the Website means you accept the latest version of the Privacy Policy.
We may link to third-party websites or services, and any information collected by those parties is governed by their privacy policies.`}</p>
      <p><strong parentName="p">{`3. Sources of information`}</strong>{`
The personal information we collect comes primarily from your interactions with us (e.g. using the Website or purchasing a product online). We may also collect information provided about you by third parties (e.g. social media platforms).
When you purchase a product from us online, you must provide accurate and personally-identifiable information. Otherwise, we will not be able to verify a legal sale, and we cannot process the transaction. Examples of such information include: your name, address, phone number, email address, date of birth, and any other information deemed necessary to process transactions.
Some information is automatically collected by us and our third-party service providers when you interact with us. This includes tracking information received from cookies, beacons, tags, and pixels. Statistical data may also be collected, including IP address, referral information, advertising data, device identifiers, and geo-location information.`}</p>
      <p><strong parentName="p">{`4. Use of information`}</strong>{`
We may use collected information to personalize and improve our products and marketing efforts.
Uses of your information include:`}</p>
      <ul>
        <li parentName="ul">{`Verifying your age and identity;`}</li>
        <li parentName="ul">{`Sharing information with compliance partners, payment processors, regulatory authorities, and others as part of the transaction process;`}</li>
        <li parentName="ul">{`Contacting you through various channels to market our products and manage your relationship with us;`}</li>
        <li parentName="ul">{`Analyzing data to improve our business;`}</li>
        <li parentName="ul">{`Any purpose which we disclose to you at the time;`}</li>
        <li parentName="ul">{`Any other purpose permitted by applicable law.`}</li>
      </ul>
      <p><strong parentName="p">{`5. Internal disclosures`}</strong>{`
We may share any collected information among various parts of our company.`}</p>
      <p><strong parentName="p">{`6. External disclosures`}</strong></p>
      <p>{`We will not sell personally-identifiable data to third-parties for their own marketing purposes.
We may share your information with third-party service providers, in accordance with the policies outlined in this document.
As permitted by, or as required by law, we may disclose information to third parties. This includes law enforcement or regulatory authorities. We may disclose any information when we deem it appropriate to protect the rights, property, or safety of our company, personnel, customers, or others, or to enforce our contractual rights.
Any information may be transferred as part of any assets and liabilities transferred at the time of a sale, merger, restructuring, or dissolution of our company.`}</p>
      <p><strong parentName="p">{`7. Additional technology`}</strong>{`
We may use analytical services, such as Google Analytics and related Google services for data collection and tracking purposes. These services are governed by the respective privacy practices of those parties.
Transactions may be processed and verified by Vinoshipper. Use of Vinoshipper and affiliated services is governed by the Vinoshipper terms of use and privacy policies.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      